import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  app: {
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    }
  },
  appHeader: {
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    gap: 40,
    fontFamily: 'Manrope',
    // boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.05)',
    // filter: 'drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.05))',
    fontSize: 16,
    '& .containerHeader': {
      justifyContent: 'space-between',
      display: 'flex',
      alignItems: 'center',
      maxWidth: '1040px',
      width: '100%'
    },
    '& .active': {
      fontWeight: 700,
      borderBottom: '2px solid #E24E06',
      padding: '5px 0',
    },
    '& .menu': {
      display: 'flex',
      gap: 60,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        gap: 20,
      }
    },
    '& .logo': {
      color: '#E24E06',
      fontFamily: 'Audiowide',
      fontWeight: 400,
      fontSize: 28,
    },
    '& .yellow': {
        color: '#E24E06'
    }
  },
  menu: {
    cursor: 'pointer',
  },
  services: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '66vh',
  },
  servicesContainer: {
    marginTop: 60,
    marginBottom: 60,
    maxWidth: '1040px',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 100,
    display: "flex",
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      gap: 50
    }
  },
  breadcrumbsContainer: { 
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between'
  },
  containerContent: {
    flexDirection: 'row',
    display: 'flex',
    gap: 20,
    marginTop: 30,
    backgroundColor: "rgba(202, 223, 184, 0.5)",
    boxShadow: "4px 4px 12px 0px green",
    borderRadius: 30,
    padding: 50,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "80%"
    }
  },
  menuContainer: {
    // filter: 'drop-shadow(4px 4px 12px rgba(0, 0, 0, 0.1))',
    minWidth: 245,
    '& .account': {
        backgroundColor: '#E24E06',
        color: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 20px',
        gap: 12
    },
    '& .active': {
        backgroundColor: '#FCEDE6',
        padding: '10px 20px',
    },
    '& .item': {
        // backgroundColor: '#fff',
        padding: '10px 20px',
        cursor: 'pointer',
    }
  },
  containerSectionServices: {
    width: "100%",
    gap: 10,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      overflowX: 'hidden'
    },
    width: '100%',
    '& .row': {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        }    
    },
    '& .spaceBetween': {
        justifyContent: 'space-between',
    },
    '& .alignItemsCenter': {
        alignItems: 'center',
    },
    '& .add-friend': {
        gap: 20,
        [theme.breakpoints.down('sm')]: {
          gap: 1,
          maxWidth: '100vw'
        }    
    },
    '& .label': {
      margin: '15px 0',
      minWidth: 140,
      [theme.breakpoints.down('sm')]: {
        margin: '10px 0',
        fontSize: 12
      },    
      '& .option': {
        color: "gray",
        [theme.breakpoints.down('sm')]: {
          fontSize: 12
        },
      }
    },
    '& .MuiInputBase-input': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },
    
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12
      },
    },

  },
  rightContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& .btnEdit': {
        backgroundColor: '#E24E06',
        color: '#fff',
        padding: '10px 25px',
        borderRadius: 8,
        fontWeight: 700,
    },
    '& .item': {
        gap: 20,
    }
  },
  btnAddFriend: {
    backgroundColor: '#E24E06',
    color: '#fff',
    padding: '10px 25px',
    borderRadius: 8,
    fontWeight: 700,
    cursor: 'pointer'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #C2C2C2',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
  },
  formControl: {
    width: '100%'
  },
  containerButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    gap: 20,
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      // gap: 1,
      justifyContent: 'flex-end',
    } 
  }
}));
