/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import axios from 'axios';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import useStyles from './style';
import { Button, Flex, Text } from '@chakra-ui/react';

const Input = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
}));

const AddColleague = () => {
//   const [addBy, setAddBy] = useState('username');
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const [params, setParams] = useState({});
  const [from, setFrom] = useState("");
  const styles = useStyles();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get('https://api.personatalenta.id/user/current', {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
    .then(res => {
      setUser(res.data.user)
    }).catch(err => {
      if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
        // navigate('/');
      }
    })
  }, []);

  useEffect(() => {
    let ignore = false;

    const openElement = () => {
      if (location.search && !ignore) {
        const item = location.search?.slice(1)?.split('?')
        const pairs = item[0]?.split('=');
        if (pairs[0] === "from") {
          setFrom(pairs[1]);
        }
      }
    };
    openElement()
  }, [location.hash]);

  const onChangeInput = e => {
    const tmpValue = {};
    tmpValue[e.target.id] = e.target.value;
    setParams({...params, ...tmpValue});
  };

  const handleSubmit = () => {
    const token = localStorage.getItem('token');
    axios.post('https://api.personatalenta.id/colleague/add', qs.stringify({...params}), {headers: {"Content-Type": 'application/x-www-form-urlencoded', Authorization: `Bearer ${token}`}})
    .then(() => {
      if (from === "matching") {
        window.location.assign('/matching')
      } else {
        window.location.assign('/dashboard/friend-list')
      }
    }).catch(err => {
      console.log('err: ', err)
      setError(err.response.data)
      // setError(err.response.data.message || err.response.data)
    })
  };
  
  return (
    <div className={styles.app}>
      <div className={styles.services}>
        <div className={styles.servicesContainer}>
          <div className={styles.containerContent}>
            <div className={styles.rightContainer}>
              {error && (
                <Flex bgColor="red" pt={2} pb={2} pl={5} pr={5} width="100%">
                  <Text color="white">{error}</Text>
                </Flex>
              )}
              <div className={styles.containerSectionServices}>
                <div className="row add-friend">
                  <h6 className="label">Display Name</h6>
                  <FormControl variant="standard" className={styles.formControl}>
                    <Input fullWidth id="name" className={styles.input} type="text" placeholder="Display Name" onChange={onChangeInput} />
                  </FormControl>
                </div>
                <div className="row add-friend">
                  <h6 className="label">Tanggal Lahir</h6>
                  <FormControl variant="standard" className={styles.formControl}>
                    <Input fullWidth id="birth_date" className={styles.input} type="date" placeholder="Tanggal Lahir" onChange={onChangeInput} />
                  </FormControl>
                </div>
                <div className="row add-friend">
                  <h6 className="label">Jam Lahir <span className="option">(optional)</span></h6>
                  <FormControl variant="standard" className={styles.formControl}>
                    <Input fullWidth id="birth_time" className={styles.input} type="time" placeholder="Jam Lahir" onChange={onChangeInput} />
                  </FormControl>
                </div>
                <div className="row add-friend">
                  <h6 className="label">Gender</h6>
                  <FormControl variant="standard" className={styles.formControlSmall}>
                    <RadioGroup
                      row
                      id="gender"
                      onChange={onChangeInput}
                    >
                      <FormControlLabel value={0} control={<Radio id="gender" />} label="Pria" />
                      <FormControlLabel value={1} control={<Radio id="gender" />} label="Wanita" />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className={styles.containerButton}>
                  {/* <Button variant="alternate" label="Cancel" /> */}
                  <Button
                    borderWidth={1}
                    borderColor="#1877f2"
                    color='#1877f2'
                    fontSize='sm'
                    fontWeight='bold'
                    borderRadius='5px'
                    px='10px'
                    py='5px'
                    onClick={() => handleSubmit()}
                    // disabled={pagination.page === 1}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default AddColleague;
