/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import useStyles from './style';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import 'swiper/css';
import logo from '../../assets/img/logo.png';
import { useLocation } from 'react-router-dom';
import Header from 'components/header';
import Footer from 'components/footerUser';

const Login = () => {
  const styles = useStyles();
  const location = useLocation()
  const [showPassword, setShowPassword] = useState(false);
  const [params, setParams] = useState({});
  const [error, setError] = useState('');
  // const [pleaseLogin, setPleaseLogin] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        axios.get('https://api.personatalenta.id/user/current', {headers: {Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode"}})
        .then(() => {
          window.location.assign('/dashboard/profile')
        }).catch((err) => {
          console.log(err)
        })
      } catch (error) {
        console.log('err', error)
      }
    }
  }, [])

  const handleLogin = () => {
    axios.post(`https://api.personatalenta.id/user/login`, qs.stringify({...params}), {headers: {'content-type': 'application/x-www-form-urlencoded'}})
    .then((res) => {
      if (res?.data?.user?.verified === 0) {
        setError("Silahkan Verifikasi Email Anda")
      } else {
        localStorage.setItem('token', res?.data?.token);
        localStorage.setItem('firebase', res?.data?.firebaseToken);
        localStorage.setItem('user', JSON.stringify(res?.data?.user));
        if (location.search?.slice(1)?.split('=')[1] === "matching") {
          window.location.assign('/matching');
        } else {
          window.location.assign('/dashboard/profile');
        }
      }
    }).catch(err => {
      setError(err.response.data.message)
    })
  };

  const onChangeInput = e => {
    const tmpValue = {};
    tmpValue[e.target.id] = e.target.value;
    setParams({...params, ...tmpValue});
  };

  return (
    <div className={styles.app}>
      <Header />
      <div className={styles.heroContainer}>
        <div className={styles.hero}>
          <div>
            {/* {pleaseLogin && (
              <p className={styles.pleaseLogin}>Silahkan Login</p>
            )} */}
            <div className={styles.loginContainer}>
              <img src={logo} className="App-logo" alt="logo" />
              <p className="title">PERSONA</p>
              {error && (
                <span className={styles.error}>{error}</span>
              )}
              <FormControl fullWidth sx={{ mt: 1, mb: 1, width: '95%' }} variant="outlined">
                <InputLabel htmlFor="username" className={styles.label}>Email/Username</InputLabel>
                <OutlinedInput fullWidth id="username" label="Email/Username" variant="outlined" onChange={onChangeInput} />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 1, mb: 1, width: '95%' }} variant="outlined">
                <InputLabel htmlFor="password" className={styles.label}>Password</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="password"
                  label="Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  onChange={onChangeInput}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <div className={styles.forgotPassword}>
                <a href='/forgot-password'>Forgot Password?</a>
              </div>
              <Button variant="contained" fullWidth className={styles.buttonLogin} onClick={() => handleLogin()}>Login</Button>
              <div className={styles.forgotPassword}>
                <span>Don’t have an account? <a href='/signup' className="yellow">Sign Up</a></span>
              </div>
            </div>
          </div>
        </div>  
      </div>
      <Footer />
    </div>
  );
}

export default Login;
