import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Avatar,
} from "@chakra-ui/react";
import axios from "axios";
import { CircularProgress, SnackbarContent } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import { useLocation } from 'react-router-dom';
import { columnsDataPurchaseHistory } from "./variables/columnsData";
import TablePurchased from "./components/TablePurchased";
import { Divider, LinearProgress } from "@mui/material";
import moment from "moment";
import { columnsDataPersonaPurchaseHistory } from "./variables/columnsDataPersona";

export default function PurchaseHistory() {
  const [user, setUser] = useState();
  const [purchased, setPurchased] = useState([]);
  const [activeType, setActiveType] = useState('profiling');
  const [loadingItems, setLoadingItems] = useState({});
  const [pagination, setPagination] = useState({ limit: 5, page: 1, max: 1, total: 1 })
  const [matchingID, setMatchingID] = useState();
  const [open, setOpen] = useState(false);
  const [matching, setMatching] = useState();
  const [cardMatching, setCardMatching] = useState([]);
  const [stats, setStats] = useState();
  const [activeCard, setActiveCard] = useState(0);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [level, setLevel] = useState('');
  const location = useLocation();

  // const navigate = useNavigate()
  // const styles = useStyles();

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
    } else {
      // navigate('/')
    }
  }, []);

  useEffect(() => {
    let ignore = false;

    const openElement = () => {
      if (location.search && !ignore) {
        const item = location.search?.slice(1)?.split('?')
        const pairs = item[0]?.split('=');
        if (pairs[0] === 'matchingID' && pairs[1]) {
          setMatchingID(Number(pairs[1]));
          setActiveType('matching');
          setOpen(true)
        }
      } else if (location.hash && !ignore) {
        const item = location.hash.slice(1)?.split('#')
        setActiveType(item[0])
      }
    };

    // Delay the open action to give time for rendering the target element
    const timeoutId = setTimeout(openElement, 500); // 500ms delay

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
      ignore = true;
    };
  }, [location.hash]);

  useEffect(() => {
    if (stats) {
      if (stats < 50) {
        setLevel('Average')
      } else if (stats < 60) {
        setLevel('Moderate')
      } else if (stats < 70) {
        setLevel('Intermediate')
      } else if (stats < 80) {
        setLevel('Leading')
      } else if (stats < 90) {
        setLevel('Advance')
      } else {
        setLevel('The BEST')
      }
    }
  }, [stats]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (activeType === 'matching') {
      axios.get(`https://api.personatalenta.id/friends/matching/list`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
        .then(res => {
          if (res?.data?.data) {
            setPurchased(res.data.data)
          }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
    } else {
      axios.get(`https://api.personatalenta.id/user/transaction?type=${activeType}&limit=${pagination.limit}&page=${pagination.page}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
        .then(res => {
          // if (res?.data?.transactions?.length > 0) {
          setPurchased(res?.data?.transactions);
          setPagination({ ...pagination, max: res.data.totalPages, total: res.data.totalCount })
          // }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })
    }
  }, [pagination.page, pagination.limit, activeType]);

  useEffect(() => {
    if (matchingID) {
      const token = localStorage.getItem('token');
      axios.get(`https://api.personatalenta.id/matching/detail/${matchingID}`, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" } })
        .then(res => {
          if (res?.data) {
            setMatching(res?.data)
            setCardMatching(res?.data?.cards_general)
            // setMatchingCard(0);
            setStats(res?.data?.stats?.general)
            setActiveCard(1)
            // setPurchased(res.data.data)
          }
        }).catch(err => {
          if (err.response.data.message === "Unauthorized" || err.response.data === "Unauthorized") {
            // navigate('/');
          }
        })

    }
  }, [matchingID])

  useEffect(() => {
    let localUser = localStorage.getItem('user');
    if (localUser) {
      setUser(JSON.parse(localUser))
      // console.log(user)
    } else {
      window.location.assign('/home')
    }
  }, []);

  const nextPage = () => {
    if (pagination.page < pagination.max) {
      setPagination({ ...pagination, page: pagination.page + 1 })
    }
  };

  const prevPage = () => {
    if (pagination.page > 1) {
      setPagination({ ...pagination, page: pagination.page - 1 })
    }
  };

  const firstPage = () => {
    setPagination({ ...pagination, page: 1 })
  };

  const lastPage = (type) => {
    setPagination({ ...pagination, page: pagination.max })
  };

  const goToPage = (type, page) => {
    setPagination({ ...pagination, page: page })
  };

  const limitPage = (type, limit) => {
    setPagination({ ...pagination, limit: limit })
  };

  const onClickAction = props => {
    if (props.type === 'Download') {
      setLoadingDownload(true)
      setLoadingItems(prev => ({ ...prev, [props.index]: true })); // Set individual item loading
      const token = localStorage.getItem('token');
      let baseUrl;
      if (props.product.user_id === user.id) {
        if (props.product.product.features === "Standard Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=standard`
        } else if (props.product.product.features === "Complete Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=complete`
        } else if (props.product.product.features === "Career Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/career`
        } else if (props.product.product.features === 'Relationship Profiling Book') {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/relation`
        } else if (props.product.product.features === 'Workstyle Profiling Book') {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/workstyle`
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=standard`
        } else if (props.product.product.features === "Complete Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/link?id=${props.product.user_id}&type=${props.product.is_has_user_id ? 0 : 1}&version=complete`
        } else if (props.product.product.features === "Career Profiling Book") {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/career?type=${props.product.is_has_user_id ? 2 : 1}&user_id=${props.product.user_id}`
        } else if (props.product.product.features === 'Relationship Profiling Book') {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/relation?type=${props.product.is_has_user_id ? 2 : 1}&user_id=${props.product.user_id}`
        } else if (props.product.product.features === 'Workstyle Profiling Book') {
          baseUrl = `https://api.personatalenta.id/profiling/pdf/workstyle?type=${props.product.is_has_user_id ? 2 : 1}&user_id=${props.product.user_id}`
        }
      }
  
      axios.get(baseUrl, { headers: { Authorization: `Bearer ${token ? token : ''}`, "Content-Type": "x-www-from-urlencode" }, responseType: 'blob' })
        .then(res => {
          // setLoading(false)
          setLoadingDownload(false)
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(res.data);
          a.download = `${props.product.product.features === "Career Profiling Book" ? "Karir Terbaik Untuk" : props.product.product.features} ${props.product.user?.name ?? props.product.colleague?.name}.pdf`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }).catch(err => {
          if (err.response?.data?.message === "Unauthorized" || err.response?.data === "Unauthorized") {
            // navigate('/');
          }
        }).finally(() => {
          setLoadingItems(prev => ({ ...prev, [props.index]: false })); // Reset loading after completion
        });
    } else if (props.type === 'Web') {
      if (user.id === props.product.user_id) {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-standard-web`)
        } else if (props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-complete-web`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/profile/${user?.username}/profiling-web`)
        } else if (props.product.product.name === "Daily Vibe Persona" || props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/profile/${user?.username}/persona-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/matching`)
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-standard-web`)
        } else if (props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-complete-web`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/profiling-web`)
        } else if (props.product.product.name === "Daily Vibe Persona" || props.product.product.name === "General Persona") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/persona-web`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/dashboard/friend-list/${props.product.is_has_user_id ? "friend" : "colleague"}/${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}/matching-web`)
        }
      }
    } else if (props.type === 'Buy') {
      if (user.id === props.product.user_id && props.product.is_has_user_id) {
        if (props.product.product.features === "Standard Profiling Book" || props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/pricing#profiling`);
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(`/pricing#persona`)
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(`/pricing#persona`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/pricing#profiling`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/pricing#matching`)
        }
      } else {
        if (props.product.product.features === "Standard Profiling Book" || props.product.product.features === "Complete Profiling Book") {
          window.location.assign(`/pricing#profiling?type=${props.product.is_has_user_id ? "friend" : "colleague"}&id=${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}`);
        } else if (props.product.product.name === "Daily Vibe Persona") {
          window.location.assign(`/pricing#persona?type=${props.product.is_has_user_id ? "friend" : "colleague"}&id=${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}`)
        } else if (props.product.product.name === "General Persona") {
          window.location.assign(`/pricing#persona?type=${props.product.is_has_user_id ? "friend" : "colleague"}&id=${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}`)
        } else if (props.product.product.name === "Profiling Web") {
          window.location.assign(`/pricing#profiling?type=${props.product.is_has_user_id ? "friend" : "colleague"}&id=${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}`)
        } else if (props.product.product.name === "Matching") {
          window.location.assign(`/pricing#matching?type=${props.product.is_has_user_id ? "friend" : "colleague"}&id=${props.product.is_has_user_id ? props.product.user.id : props.product.colleague.id}`)
        }
      }
    }
  }

  const onChangeMenu = menu => {
    setPurchased([])
    setActiveType((menu))
    // if (menu === 'profiling') {
    //   setActiveType(0);
    // } else if (menu === 1) {
    //   setActiveType(1);
    // } else if (menu === 2) {
    //   setActiveType(2);
    // }
  }

  const setDetailMatching = item => {
    if (moment(item.expired_at).isAfter(new Date, 'days')) {
      if (matchingID !== item.id) {
        setMatchingID(item.id)
        if (item.expired_at && moment(item.expired_at).isAfter(new Date)) {
          setOpen(true)
        }
      } else {
        setMatchingID();
        setOpen(false)
      }
    } else {
      window.location.assign('/pricing#matching')
    }
  }

  const onClickCard = props => {
    setActiveCard(props);
    // setMatchingCard(props);
    if (matching) {
      if (props === 1) {
        setCardMatching(matching.cards_general)
        setStats(matching?.stats?.general)
      } else if (props === 2) {
        setCardMatching(matching.cards_daily)
        setStats(matching?.stats?.today)
      }
    }
  }

  const handleClose = () => {
    setLoadingDownload(false);
  }

  const action = (
    <div>
      <CircularProgress size={25} />
    </div>
  )

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  return (
    <Box overflowX={{ sm: "scroll", lg: "hidden" }} pt={{ sm: 5, base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "20px", xl: "20px" }} mt={10}>
        {/* <Banner
          // gridArea='1 / 1 / 2 / 2'
          avatar={user?.photo}
          name={user?.name}
          job={user?.occupation}
          dob={user?.birth_date}
        /> */}
        <Flex gap={{ sm: 1, md: 30 }} flex={1}>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('profiling')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'profiling' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'profiling' ? '#FFF' : '#000'} fontWeight={activeType === 'profiling' ? 'bold' : 'normal'}>PROFILING SERVICE</Text>
          </Flex>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('matching')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'matching' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'matching' ? '#FFF' : '#000'} fontWeight={activeType === 'matching' ? 'bold' : 'normal'}>MATCHING SERVICE</Text>
          </Flex>
          <Flex width={{ sm: '100%', md: "20%" }} cursor="pointer" onClick={() => onChangeMenu('persona')} borderColor="#059212" borderWidth={1} borderTopRadius={8} padding="5px 10px" backgroundColor={activeType === 'persona' ? '#059212' : '#fff'}>
            <Text fontSize={{ sm: 'xs', md: 'sm' }} color={activeType === 'persona' ? '#FFF' : '#000'} fontWeight={activeType === 'persona' ? 'bold' : 'normal'}>PERSONA SERVICE</Text>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" style={{ width: '75%' }} />
        {activeType === 'matching' ? (
          <Flex flexDir="row" gap={5} flexWrap="wrap" width={{ sm: '100%', md: '75%' }} mt={{ sm: 10, md: 0 }}>
            <Flex borderBottomWidth={1} flexDirection="row" py={2} gap={5} minWidth="45%">
              <Flex justifyContent="center" borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} minWidth={160}>
                <Text color="#000" fontWeight="bold">Matched Name</Text>
              </Flex>
              <Flex borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} gap={1} justifyContent="center">
                <Text color="#000" fontWeight="bold">Service</Text>
              </Flex>
              <Flex borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} width={{ sm: 130, md: 370 }} gap={2} justifyContent="center">
                <Text color="#000" fontWeight="bold">Matched Result</Text>
              </Flex>
              <Flex borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} width={{ sm: 100, md: 100 }} gap={2} justifyContent="center">
                <Text color="#000" fontWeight="bold">Action</Text>
              </Flex>
              <Flex borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} width={{ sm: 100, md: 200 }} gap={2} justifyContent="center">
                <Text color="#000" fontWeight="bold">Tanggal Beli</Text>
              </Flex>
              <Flex borderColor="#CADFB8" borderWidth={1} borderTopRadius={8} padding="5px 2px" backgroundColor={'#CADFB8'} width={{ sm: 100, md: 200 }} gap={2} justifyContent="center">
                <Text color="#000" fontWeight="bold">Tanggal Expired</Text>
              </Flex>
            </Flex>
            {purchased.length > 0 && purchased.map((item, index) => (
              <Flex key={index} direction="column">
                <Flex borderBottomWidth={1} flexDirection="row" py={2} gap={5} minWidth="45%">
                  <Flex direction="column" justifyContent="center" minWidth={70}>
                    <Avatar
                      src={item.users?.user1?.photo}
                      h='40px'
                      w='40px'
                      border='1px solid #059212'
                    // borderColor={borderColor}
                    />
                    <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user1?.name.split(' ')[0]}</Text>
                  </Flex>
                  <Flex direction="column" justifyContent="center" minWidth={70}>
                    <Avatar
                      src={item.users?.user2?.photo}
                      h='40px'
                      w='40px'
                      border='1px solid #059212'
                    // borderColor={borderColor}
                    />
                    <Text textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" maxWidth={70}>{item?.users?.user2?.name.split(' ')[0]}</Text>
                  </Flex>
                  <Flex direction="column" gap={1} justifyContent="center">
                    <Text>General</Text>
                    <Text>Daily</Text>
                  </Flex>
                  <Flex direction="column" width={{ sm: 130, md: 250 }} gap={2} justifyContent="center">
                    <Box width="100%" position="relative">
                      <LinearProgress sx={{ height: 24, borderRadius: 1, width: '100%', position: 'absolute' }} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item?.stats?.general} />
                      <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item?.stats?.general}%</Text>
                    </Box>
                    <Box width="100%" position="relative">
                      <LinearProgress sx={{ height: 24, borderRadius: 1, width: '100%', position: 'absolute' }} color={item.matching_type === 0 ? "warning" : item.matching_type === 1 ? "error" : "info"} variant="determinate" value={item?.stats?.today} />
                      <Text color="white" fontWeight="bold" zIndex={1} position="relative" textAlign="center">{item?.stats?.today}%</Text>
                    </Box>
                  </Flex>
                  <Flex alignItems="center" justifyContent="center" cursor="pointer" onClick={() => setDetailMatching(item)} width={{ sm: 70, md: 70 }} direction="column" gap={1}>
                    <Text bgColor={item.expired_at && moment(item.expired_at).isAfter(new Date) ? "green" : "#213ED1"} borderRadius={5} textAlign="center" width="100%" py="5px" color="#fff">{item.expired_at && moment(item.expired_at).isAfter(new Date) ? "View" : "Beli lagi"}</Text>
                  </Flex>
                  <Flex width={{ sm: 130, md: 130 }} direction="column" gap={1} justifyContent="center">
                    <Text>{moment(item.created_at).format('DD MMM YYYY')}</Text>
                  </Flex>
                  <Flex width={{ sm: 130, md: 130 }} direction="column" gap={1} justifyContent="center">
                    <Text>{item.expired_at ? moment(item.expired_at).format('DD MMM YYYY') : "-"}</Text>
                  </Flex>
                </Flex>
                {(item.id === matchingID && open) && (
                  <div>
                    <Flex gap={3}>
                      <Button
                        bgColor={cardMatching.length === 0 ? "gray" : activeCard === 1 ? "#3572EF" : "white"}
                        color={activeCard === 1 || cardMatching.length === 0 ? "white" : "#3572EF"}
                        borderWidth={1}
                        borderColor="#3572EF"
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='bold'
                        borderRadius='5px'
                        onClick={() => onClickCard(1)}
                        // flex={{ sm: 1 }}
                        disabled={cardMatching.length === 0}
                        px={{ sm: '10px', md: '30px' }}
                        py='5px'>
                        General match
                      </Button>
                      <Button
                        bgColor={cardMatching.length === 0 ? "gray" : activeCard === 2 ? "#3572EF" : "white"}
                        color={activeCard === 2 || cardMatching.length === 0 ? "white" : "#3572EF"}
                        borderWidth={1}
                        borderColor="#3572EF"
                        fontSize={{ sm: 'xs', md: 'sm' }}
                        fontWeight='bold'
                        borderRadius='5px'
                        onClick={() => onClickCard(2)}
                        disabled={cardMatching.length === 0}
                        // flex={{ sm: 1 }}
                        px={{ sm: '10px', md: '30px' }}
                        py='5px'>
                        Daily match
                      </Button>
                    </Flex>
                    {matching && (
                      <Flex flexDirection="column" width={{ md: '100%' }} borderWidth={1} borderRadius={15} px={0} py={5} gap={5}>
                        {cardMatching.map((card, index) => (
                          <Flex direction="column" key={index}>
                            <Text fontWeight="bold" textTransform="uppercase" color="#B82132" fontSize={{ sm: "sm", md: "md" }}>{card.title}</Text>
                            {card.texts.map((text) => (
                              <>
                                {
                                  text.map((item, i) => {
                                    // if (item.title && item.text) {
                                    return (
                                      <Flex key={i} direction="column" gap={1} borderWidth={0.5} padding={2} borderColor="lightgrey">
                                        <Text fontWeight="bold" fontSize={{ sm: "xs", md: "md" }}>{item.title != 'null' && item.title}</Text>
                                        <Text whiteSpace="pre-line" fontSize={{ sm: "xs", md: "md" }}>{item.text}</Text>
                                        {/* <Text color="#050C9C" fontSize={{ sm: "xs", md: "md" }} fontStyle="italic" textAlign="end" mt={0} cursor="pointer" onClick={() => onClickOpen(item.text)}>{isUnlocked && item.text !== open ? "Read more" : isUnlocked && item.text === open ? "Show less" : "Deep dive"}</Text> */}
                                      </Flex>
                                    )
                                    // }
                                  })
                                }
                              </>
                            ))}
                          </Flex>
                        )
                        )}
                      </Flex>
                    )}
                  </div>
                )}
              </Flex>
            ))}
          </Flex>
        ) : (
          <TablePurchased
            columnsData={activeType === "persona" ? columnsDataPersonaPurchaseHistory : columnsDataPurchaseHistory}
            tableData={purchased}
            pagination={pagination}
            nextPage={nextPage}
            prevPage={prevPage}
            firstPage={firstPage}
            lastPage={lastPage}
            limitPage={limitPage}
            goToPage={goToPage}
            onClickAction={onClickAction}
            loading={loadingItems}
          />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={loadingDownload}
        onClose={handleClose}
      >
        <SnackbarContent style={{ height: 100 }}
          action={action}
          message={<span id="client-snackbar">Saat ini dokumen sedang proses download, mohon ditunggu</span>}
        />
      </Snackbar>
    </Box>
  );
}
